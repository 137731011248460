/**
 * 路由组件出口文件
 * yezi 2018年6月24日
 */
import Loadable from "react-loadable";
import Loading from "./widget/Loading";

export default {
    WysiwygBundle: Loadable({
        // 按需加载富文本配置
        loader: () => import("./Wysiwyg"),
        loading: Loading,
    }),

    AppraiseInvite: Loadable({
        loader: () => import("../pages/appraise/AppraiseInvite"),
        loading: Loading,
    }),
    AppraiseAudit: Loadable({
        loader: () => import("../pages/appraise/AppraiseAudit"),
        loading: Loading,
    }),

    CourseDetail: Loadable({
        loader: () => import("../pages/course/CourseDetail"),
        loading: Loading,
    }),
    CourseSource: Loadable({
        loader: () => import("../pages/course/CourseSource"),
        loading: Loading,
    }),
    CourseSetting: Loadable({
        loader: () => import("../pages/course/CourseSetting"),
        loading: Loading,
    }),
    Class: Loadable({
        loader: () => import("../pages/course/Class"),
        loading: Loading,
    }),

    Log: Loadable({
        loader: () => import("../pages/log/Log"),
        loading: Loading,
    }),

    Login: Loadable({
        loader: () => import("../pages/Login"),
        loading: Loading,
    }),

    Member: Loadable({
        loader: () => import("../pages/member/Member"),
        loading: Loading,
    }),
    MemberApply: Loadable({
        loader: () => import("../pages/member/MemberApply"),
        loading: Loading,
    }),
    MemberAudit: Loadable({
        loader: () => import("../pages/member/MemberAudit"),
        loading: Loading,
    }),

    Studio: Loadable({
        loader: () => import("../pages/studio/Studio"),
        loading: Loading,
    }),

    My: Loadable({
        loader: () => import("../pages/user/My"),
        loading: Loading,
    }),
    Role: Loadable({
        loader: () => import("../pages/user/Role"),
        loading: Loading,
    }),
    User: Loadable({
        loader: () => import("../pages/user/User"),
        loading: Loading,
    }),
} as any;
