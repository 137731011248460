export interface IFMenuBase {
    key: string;
    title: string;
    icon?: string;
    component?: string;
    query?: string;
    requireAuth?: string;
    route?: string;
    /** 是否登录校验，true不进行校验（访客） */
    login?: boolean;
    config?: any;
}

export interface IFMenu extends IFMenuBase {
    subs?: IFMenu[];
}

const menus: {
    menus: IFMenu[];
    others: IFMenu[] | [];
    [index: string]: any;
} = {
    menus: [
        {
            key: "/opmweb/index",
            title: "我的账户",
            icon: "user",
            component: "My",
        },
        {
            key: "/opmweb/account",
            title: "账户管理",
            icon: "team",
            subs: [
                {
                    key: "/opmweb/account/role",
                    title: "角色管理",
                    component: "Role",
                },
                {
                    key: "/opmweb/account/user",
                    title: "账户管理",
                    component: "User",
                },
            ],
        },
        {
            key: "/opmweb/member",
            title: "会员管理",
            icon: "audit",
            subs: [
                {
                    key: "/opmweb/member/apply",
                    title: "会员申请",
                    component: "MemberApply",
                },
                {
                    key: "/opmweb/member/audit",
                    title: "会员申请审核",
                    component: "MemberAudit",
                },
                {
                    key: "/opmweb/member/manage",
                    title: "会员管理",
                    component: "Member",
                },
            ],
        },
        {
            key: "/opmweb/course",
            title: "课程管理",
            icon: "solution",
            subs: [
                {
                    key: "/opmweb/course/source",
                    title: "素材管理",
                    component: "CourseSource",
                },
                {
                    key: "/opmweb/course/setting",
                    title: "课程设置",
                    component: "CourseSetting",
                },
                {
                    key: "/opmweb/course/class",
                    title: "课堂管理",
                    component: "Class",
                },
            ],
        },
        {
            key: "/opmweb/appraise",
            title: "评价管理",
            icon: "like",
            subs: [
                {
                    key: "/opmweb/appraise/invite",
                    title: "课程评价邀请",
                    component: "AppraiseInvite",
                },
                {
                    key: "/opmweb/appraise/audit",
                    title: "评价审核",
                    component: "AppraiseAudit",
                },
            ],
        },
        {
            key: "/opmweb/studio",
            title: "画室管理",
            icon: "bank",
            component: "Studio",
        },
        {
            key: "/opmweb/log",
            title: "日志管理",
            icon: "file",
            component: "Log",
        },
    ],
    others: [
        {
            key: "/opmweb/course/setting/:courseId",
            title: "课程详情",
            icon: "solution",
            component: "CourseDetail",
        },
    ], // 非菜单相关路由
};

function getAllMenus(
    allMenus: { [key: string]: IFMenuBase } = {},
    menus: IFMenu[]
): { [key: string]: IFMenuBase } {
    menus.map((menu: IFMenu, index: number) => {
        allMenus[menu.key] = menu;
        if (menu.subs) {
            allMenus = getAllMenus(allMenus, menu.subs);
        }
    });
    return allMenus;
}

export function getUserMenus(autList: any[]): IFMenu[] {
    const allMenus: { [key: string]: IFMenuBase } = getAllMenus(
        {},
        menus.menus
    );

    let userMenus: IFMenu[] = [];
    autList.map((item) => {
        let subs: IFMenuBase[] = [];
        if (item.menuList && item.menuList.length) {
            subs = item.menuList.map((subItem: any) => {
                return allMenus[subItem.url];
            });
        }

        let menu: IFMenu = allMenus[item.url];
        // console.log(item.url, menu);
        if (menu) {
            if (subs.length) {
                menu.subs = subs;
            }
            userMenus.push(menu);
        }
        return menu;
    });

    userMenus = userMenus.concat(menus.others);
    return userMenus;
}

// console.log("getAllMenus", getAllMenus({}, menus.menus));

export default menus;
