import { createHashHistory } from "history";
import Http from "./Http";
import { METHOD, DATA_TYPE, CACHE_ENUM } from "./HttpEmun";
import Config from "../Config";
import { message } from "antd";

const history = createHashHistory();

export default class PluginHttp {
    static STATUS = {
        //加载中
        LOADING: 1,
        //加载成功
        SUCCESS: 2,
        //加载失败
        ERROR: 3,
    };

    /**
     * ajax请求插件
     * @action in {url:*地址,headers:参数}
     * @action out {loading:*地址,data:参数}
     * @param {*} next
     * @param {*} action
     * @param {*} store
     */
    static ajax(options: any) {
        let { headers, url } = options;

        const COMMON_HEADERS = {
            // userId: "2088712389846583",
        };

        headers = {
            ...COMMON_HEADERS,
            ...headers,
        };

        if (options.dataType !== "jsonp") {
            headers = JSON.stringify(headers);
        }

        const { success, error } = options;
        Http.ajax({
            url: Config.serverUrl + url,
            method: options.post || METHOD.POST,
            dataType: options.dataType || DATA_TYPE.JSON,
            cache: CACHE_ENUM.NO_CACHE,
            timeout: options.timeout || 15000,
            body: headers,
        })
            .then((data) => {
                if (data.returnCode === "40220") {
                    message.error("请登录后再试");
                    history.push("/login");
                }

                success && success(data);
            })
            .catch((e) => {
                console.error("PluginHttp, ajax请求失败", e);
                error && error(e);
            });
    }
}
