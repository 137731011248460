/**
 * Created by hao.cheng on 2017/5/7.
 */
import React from "react";

export default class Error extends React.Component {
    state = {
        animated: "",
    };
    enter = () => {
        this.setState({ animated: "hinge" });
    };
    render() {
        return (
            <div
                className="center"
                style={{
                    width: "100%",
                    height: "100%",
                    background: "#ececec",
                    overflow: "hidden",
                }}
            >
                <p>系统异常，请稍后再试</p>
            </div>
        );
    }
}
