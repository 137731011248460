import React from "react";
import { Spin, Icon } from "antd";

export default () => (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
        }}
    >
        <Spin
            tip="页面加载中"
            indicator={<Icon type="loading" style={{ fontSize: 24 }} />}
        />
    </div>
);
