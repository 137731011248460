const origin = window.location.origin;
const production = /admin.uadvip.com/.test(origin);
const isLocal = /localhost/.test(origin);

const Config = {
    userId: "",
    // serverUrl: "http://testapi.uadvip.com/opmwx/",
    // serverUrl: production ? origin + "/" : "http://39.98.70.64:8082/",
    serverUrl: isLocal ? "/api/" : "/",

    wxLoginCallbackServer: production
        ? origin + "/"
        : "http://testapi.uadvip.com/",

    bindParentUrl: "http://study.uadvip.com/",
};

export default Config;
