/**
 * Created by hao.cheng on 2017/4/13.
 */
import React, { Component } from "react";
import SiderCustom from "./SiderCustom";
import {
    Menu,
    Icon,
    Layout,
    Popover,
    Modal,
    Form,
    Input,
    Button,
    message,
} from "antd";
import { FormProps } from "antd/lib/form";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connectAlita } from "redux-alita";
import Http from "../utils/http/Http";
import Config from "../utils/Config";
import { DATA_TYPE, CACHE_ENUM } from "../utils/http/HttpEmun";

import "./HeaderCustom.scss";

const { Header } = Layout;
const SubMenu = Menu.SubMenu;

type HeaderCustomProps = RouteComponentProps<any> & {
    toggle: () => void;
    collapsed: boolean;
    responsive?: any;
    path?: string;
    userInfo?: any;
    setAlitaState?: (param: any) => void;
    history?: History;
} & FormProps;
type HeaderCustomState = {
    visible: boolean;
    modifyVisible: boolean;
};

class HeaderCustom extends Component<HeaderCustomProps, HeaderCustomState> {
    state = {
        visible: false,
        modifyVisible: false,
    };

    componentDidMount() {}

    menuClick = (e: { key: string }) => {
        // e.key === 'logout' && this.logout();
    };

    logout = () => {
        Modal.confirm({
            content: "确认退出管理云台?",
            okText: "确认",
            cancelText: "取消",
            onOk: () => {
                const { setAlitaState, history } = this.props;
                setAlitaState!({
                    stateName: "userInfo",
                    data: null,
                });

                history.push("/login");
            },
        });
    };

    popoverHide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = (visible: boolean) => {
        this.setState({ visible });
    };

    renderModifyModal = () => {
        const { getFieldDecorator } = this.props.form!;

        return (
            <Modal
                title="修改密码"
                visible={this.state.modifyVisible}
                footer={null}
                style={{ maxWidth: "400px" }}
                closable={false}
            >
                <Form onSubmit={this.onSubmit}>
                    <Form.Item>
                        {getFieldDecorator("oldPassword", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入旧密码!",
                                },
                            ],
                        })(
                            <Input
                                prefix={
                                    <Icon
                                        type="lock"
                                        style={{
                                            fontSize: 13,
                                        }}
                                    />
                                }
                                type="password"
                                placeholder="请输入旧密码"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("password", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入新密码!",
                                },
                            ],
                        })(
                            <Input
                                prefix={
                                    <Icon
                                        type="lock"
                                        style={{
                                            fontSize: 13,
                                        }}
                                    />
                                }
                                // type="password"
                                placeholder="请输入新密码"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator("confimPassword", {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入确认密码!",
                                },
                            ],
                        })(
                            <Input
                                prefix={
                                    <Icon
                                        type="lock"
                                        style={{
                                            fontSize: 13,
                                        }}
                                    />
                                }
                                // type="password"
                                placeholder="请输入确认密码"
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className="login-form-button"
                            style={{ width: "100%" }}
                            onClick={() => {
                                console.log("adsfadsfadfsfasfads");
                                this.setState({
                                    modifyVisible: false,
                                });
                            }}
                        >
                            取消
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            style={{ width: "100%" }}
                        >
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    modifyPass = () => {
        // Modal.PB
        this.setState({
            modifyVisible: true,
        });
    };

    ajax = (url: string, headers: { [key: string]: any }) => {
        return Http.ajax({
            url: Config.serverUrl + url,
            method: "post",
            dataType: DATA_TYPE.JSON,
            cache: CACHE_ENUM.NO_CACHE,
            timeout: 15000,
            body: headers,
        });
    };

    onSubmit = (e: any) => {
        e.preventDefault();
        const { form, setAlitaState, history } = this.props;
        form!.validateFields((err, values) => {
            if (!err) {
                this.ajax("modifyPass", values)
                    .then((data) => {
                        if (data.returnCode === "10000") {
                            setAlitaState!({
                                stateName: "userInfo",
                                data: null,
                            });
                            history.push("/login");
                        } else {
                            message.error(data.returnDesc);
                        }
                    })
                    .catch((e) => {
                        message.error("网络异常，请稍候再试");
                    });
            }
        });
    };

    render() {
        const {
            responsive = { data: {} },
            userInfo = { data: {} },
        } = this.props;

        const user = userInfo.data || {};

        // console.log("---responsive----", this.props, this.state.modifyVisible);
        return (
            <Header className="custom-theme header">
                {responsive.data.isMobile ? (
                    <Popover
                        content={<SiderCustom popoverHide={this.popoverHide} />}
                        trigger="click"
                        placement="bottomLeft"
                        visible={this.state.visible}
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <Icon
                            type="bars"
                            className="header__trigger custom-trigger"
                        />
                    </Popover>
                ) : (
                    <Icon
                        className="header__trigger custom-trigger"
                        type={
                            this.props.collapsed ? "menu-unfold" : "menu-fold"
                        }
                        onClick={this.props.toggle}
                    />
                )}

                {/* <Icon type="logout" /> */}

                <Menu
                    mode="horizontal"
                    style={{ lineHeight: "64px", float: "right" }}
                    onClick={this.menuClick}
                >
                    <SubMenu
                        title={<span>欢迎你，{user.userName}</span>}
                        className="user-modal"
                    >
                        <Menu.Item key="setting:2">
                            <span onClick={this.modifyPass}>修改密码</span>
                        </Menu.Item>
                        <Menu.Item key="logout">
                            <span onClick={this.logout}>退出</span>
                        </Menu.Item>
                    </SubMenu>
                </Menu>

                {this.renderModifyModal()}
            </Header>
        );
    }
}

// 重新设置连接之后组件的关联类型
const HeaderCustomConnect: React.ComponentClass<
    HeaderCustomProps,
    HeaderCustomState
> = connectAlita(["responsive", "userInfo"])(Form.create()(HeaderCustom));

export default withRouter(HeaderCustomConnect);
