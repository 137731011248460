/**
 * Created by 叶子 on 2017/8/13.
 */
import React, { Component } from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router-dom";
import DocumentTitle from "react-document-title";
import AllComponents from "../components";
import routesConfig, { IFMenuBase, IFMenu, getUserMenus } from "./config";
import queryString from "query-string";
// import { checkLogin } from "../utils";
import { connectAlita } from "redux-alita";
import { checkLogin } from "../utils";

type CRouterProps = RouteComponentProps<any> & {
    userInfo: any;
    history: History;
    setAlitaState: (param: any) => void;
};

type CRouterState = {};

class CRouter extends Component<CRouterProps, CRouterState> {
    getMenuList = (): any[] | null => {
        const { userInfo = { data: {} } } = this.props;
        const user = userInfo.data || {};
        const routes = getUserMenus(user.menuList || []);

        return user.menuList ? routes : null;
    };

    requireAuth = (permit: any, component: React.ReactElement) => {
        const menuList = this.getMenuList();
        if (!menuList || !menuList.includes(permit))
            return <Redirect to={"404"} />;
        // return null;
        return component;
    };

    requireLogin = (component: React.ReactElement, permit: any) => {
        const menuList = this.getMenuList();
        if (!checkLogin(menuList)) {
            // 线上环境判断是否登录
            return <Redirect to={"/login"} />;
        }
        return permit ? this.requireAuth(permit, component) : component;
    };

    iterteMenu = (r: IFMenu) => {
        const route = (r: IFMenuBase) => {
            // console.log("-----r-----", r);
            const Component = r.component && AllComponents[r.component];
            return (
                <Route
                    key={r.route || r.key}
                    exact
                    path={r.route || r.key}
                    render={(props) => {
                        const reg = /\?\S*/g;
                        // 匹配?及其以后字符串
                        const queryParams = window.location.hash.match(reg);
                        // 去除?的参数
                        const { params } = props.match;
                        Object.keys(params).forEach((key) => {
                            params[key] =
                                params[key] && params[key].replace(reg, "");
                        });
                        props.match.params = { ...params };
                        const merge = {
                            ...props,
                            query: queryParams
                                ? queryString.parse(queryParams[0])
                                : {},
                        };
                        // 重新包装组件
                        const wrappedComponent = (
                            <DocumentTitle title={r.title}>
                                <Component {...merge} title={r.title} />
                            </DocumentTitle>
                        );
                        return this.requireLogin(
                            wrappedComponent,
                            r.requireAuth
                        );
                    }}
                />
            );
        };

        const subRoute = (r: IFMenu): any =>
            r.subs &&
            r.subs.map((subR: IFMenu) =>
                subR.subs ? subRoute(subR) : route(subR)
            );

        // console.log("adfsadfsdfadffadasd", r);
        return r.component ? route(r) : subRoute(r);
    };

    createRoute = (key: string) => {
        return routesConfig[key].map(this.iterteMenu);
    };

    render() {
        const routes = this.getMenuList() || [];

        // console.log("routes", routes);
        return (
            <Switch>
                {/* {Object.keys(routesConfig).map((key) => this.createRoute(key))} */}
                {routes.map(this.iterteMenu)}
                {/* {!Cookie.get("userInfo") ? (
                    <Route render={() => <Redirect to="/login" />} />
                ) : null} */}
                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        );
    }
}

export default connectAlita(["userInfo"])(CRouter);
