import React, { Component } from "react";
import Routes from "./routes";
import DocumentTitle from "react-document-title";
import SiderCustom from "./components/SiderCustom";
import HeaderCustom from "./components/HeaderCustom";
import { Layout, message } from "antd";
import { connectAlita } from "redux-alita";
import PluginHttp from "./utils/http/PluginHttp";
import Cookie from "./utils/Cookie";
import Config from "./utils/Config";

const { Content, Footer } = Layout;

type AppProps = {
    setAlitaState: (param: any) => void;
    userInfo: any;
    responsive: any;
    history: any;
};

class App extends Component<AppProps> {
    state = {
        collapsed: false,
        title: "",
    };

    componentWillMount() {
        this.getClientWidth();
        window.onresize = () => {
            this.getClientWidth();
        };

        this.getUserInfo();
        // if (Cookie.get("userInfo")) {
        //     this.getUserInfo();
        // } else {
        //     this.props.history.push("/login");
        // }
    }

    getUserInfo = (): void => {
        PluginHttp.ajax({
            url: "getOpmMenuInfo",
            headers: {
                userId: Cookie.get("userInfo"),
            },
            success: (data: any) => {
                const { setAlitaState } = this.props;

                if (data.returnCode === "10000") {
                    setAlitaState({
                        stateName: "userInfo",
                        data: data,
                    });

                    Config.userId = data.userId;
                } else if (data.returnCode === "40220") {
                    message.error(data.returnDesc || "网络异常，请稍候再试");
                    this.props.history.push("/login");
                } else {
                    message.error(data.returnDesc || "网络异常，请稍候再试");
                    // this.props.history.push("/login");
                }
            },
            error: () => {
                message.error("网络异常，请稍候再试");
            },
        });
    };

    getClientWidth = () => {
        // 获取当前浏览器宽度并设置responsive管理响应式
        const { setAlitaState } = this.props;
        const clientWidth = window.innerWidth;
        // console.log(clientWidth);
        setAlitaState({
            stateName: "responsive",
            data: { isMobile: clientWidth <= 992 },
        });
    };

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const { title } = this.state;
        const { userInfo } = this.props;
        const auth = (userInfo || {}).data;

        return (
            <DocumentTitle title={title}>
                <Layout>
                    <SiderCustom collapsed={this.state.collapsed} />

                    <Layout style={{ flexDirection: "column" }}>
                        <HeaderCustom
                            toggle={this.toggle}
                            collapsed={this.state.collapsed}
                        />

                        <Content
                            style={{
                                margin: "0 16px",
                                overflow: "initial",
                                flex: "1 1 0",
                            }}
                        >
                            {auth ? <Routes userInfo={auth || {}} /> : null}
                            {/* <Routes userInfo={auth || {}} /> */}
                        </Content>

                        <Footer
                            style={{
                                textAlign: "center",
                                color: "#808080",
                                fontSize: "12px",
                            }}
                        >
                            <p style={{ margin: 0 }}>
                                本网站是UAD艺术实验室
                                唯一官方网站，所有内容具有独立知识产权及著作权。
                            </p>
                            <p style={{ margin: 0 }}>
                                Copyright ©2019-{new Date().getFullYear()}{" "}
                                FINCOM Corporation. All Rights Reserved
                            </p>
                        </Footer>
                    </Layout>
                </Layout>
            </DocumentTitle>
        );
    }
}

export default connectAlita(["userInfo", "responsive"])(App);
