import React from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Error from "./pages/Error";
import AllComponents from "./components";
import App from "./App";

export default () => (
    <Router>
        <Switch>
            <Route
                exact
                path="/"
                render={() => <Redirect to="/opmweb/index" push />}
            />
            <Route path="/opmweb" component={App} />
            <Route path="/404" component={NotFound} />
            <Route path="/login" component={AllComponents.Login} />
            <Route path="/error" component={Error} />

            <Route component={NotFound} />
        </Switch>
    </Router>
);
