import { MODE, METHOD, CACHE_ENUM, CREDENTIAL, DATA_TYPE } from "./HttpEmun";
import fetchJsonp from "fetch-jsonp";
// import { fetch } from "whatwg-fetch";
// import Promise from "es6-promise";

interface IHttpOptions {
    url: string;
    dataType?: DATA_TYPE;
    body?: any;
    method: string;
    mode?: string;
    cache?: string;
    timeout?: number;
    credentials?: string;
}

class Http {
    private defaultOptions: RequestInit = {
        // url: location.href,
        method: METHOD.POST, // *GET, POST, PUT, DELETE, etc.
        mode: MODE.CORS, // no-cors, cors, *same-origin

        cache: CACHE_ENUM.DEFAULT, // *default, no-cache, reload, force-cache, only-if-cached
        credentials: CREDENTIAL.SAME, // include, *same-origin, omit
        // timeout: 1500, // 超时时间
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
        },
        // body: JSON.stringify(data), // body data type must match "Content-Type" header
    };

    private timeout: number = 1500;

    /**
     * get请求
     *
     * @param {String} url
     * @param {Object} data
     * @param {Function} callback
     * @param {*} dataType
     * @returns
     * @memberof Http
     */
    public get(url: string, data: any, dataType?: DATA_TYPE) {
        return this.ajax({
            url,
            body: data,
            method: METHOD.GET,
            dataType,
        });
    }

    /**
     * post请求
     *
     * @param {String} url
     * @param {Object} data
     * @param {*} callback
     * @param {*} dataType
     * @returns
     * @memberof Http
     */
    public post(url: string, data: any, dataType?: DATA_TYPE) {
        return this.ajax({
            url,
            body: data,
            method: METHOD.POST,
            dataType,
        });
    }

    /**
     * ajax请求
     *
     * @param {*} url
     * @param {*} options
     * @memberof Http
     */
    public ajax(options: IHttpOptions) {
        let { url, method, dataType } = options;

        // Get请求url
        if (method === METHOD.GET || dataType === DATA_TYPE.JSONP) {
            url =
                url +
                (/\?/.test(url) ? "&" : "?") +
                this._obj2Str(options.body);
        }

        if (dataType === DATA_TYPE.JSONP) {
            const init: fetchJsonp.Options = this._initFetchJsonp(options);
            return this._fetchWithJsonp(url, init, options);
        } else {
            const init: RequestInit = this._initFetch({
                ...this.defaultOptions,
                ...options,
                mode: MODE.CORS,
            });
            return this._fetchWithCors(url, init, options);
        }
    }

    public getScript(url: string) {
        return new Promise((resolve, reject) => {
            let script = document.createElement("script");
            script.src = url;
            script.onload = function () {
                resolve(script);
            };
            script.onerror = function (err) {
                reject(err);
            };

            (document.querySelector("head") as HTMLElement).appendChild(script);
        });
    }

    /**
     * 跨域请求方法
     * @param {*} url
     * @param {*} options
     */
    private _fetchWithCors(
        url: string,
        init: RequestInit,
        options: IHttpOptions
    ) {
        const { timeout, dataType } = options;
        return this._timeoutFetch(fetch(url, init), timeout as number)
            .then(this._checkStatus)
            .then((response: Response) =>
                this._parseResult(response, dataType as DATA_TYPE)
            )
            .catch((error: Error) => {
                console.error("Fetch request failed", error, url, init);
                throw error;
            });
    }

    /**
     * JSONP请求方法
     * @param {*} url
     * @param {*} options
     */
    private _fetchWithJsonp(
        url: string,
        init: fetchJsonp.Options,
        options: IHttpOptions
    ) {
        return fetchJsonp(url, init)
            .then(this._checkStatus)
            .then((response) => this._parseResult(response, DATA_TYPE.JSONP))
            .catch((error) => console.error("Fetch request failed", error));
    }

    /**
     * 延时
     * 延时timeout不是请求连接超时的含义，而是表示请求的response时间，包括请求的连接、服务器处理及服务器响应回来的时间；fetch的timeout即使超时发生了，本次请求也不会被abort丢弃掉，它在后台仍然会发送到服务器端，只是本次请求的响应内容被丢弃而已
     * @param {*} fetchPromise  fetch请求返回的Promise
     * @param {number} timeout 单位：毫秒
     * @returns 返回Promise
     * @memberof Http
     */
    private _timeoutFetch(
        fetchPromise: Promise<Response> | Promise<fetchJsonp.Response>,
        timeout: number
    ) {
        let timeoutFn: (() => void) | null = null,
            timer: number;
        const timeoutPromise = new Promise((resolve, reject) => {
            timeoutFn = () => {
                // console.log("------_timeoutFetch-------");
                reject({ msg: "请求超时!" });
            };
        });
        timer = window.setTimeout(() => {
            timeoutFn && timeoutFn();
            clearTimeout(timer);
        }, timeout);

        //这里使用Promise.race，以最快 resolve 或 reject 的结果来传入后续绑定的回调
        const abortablePromise = Promise.race([fetchPromise, timeoutPromise]);

        return abortablePromise;
    }

    /**
     * response状态检验
     * @param {*} response
     */
    private _checkStatus(response: any) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            let error: Error = new Error(response.statusText);
            (error as any).response = response;
            throw error;
        }
    }

    /**
     * 获取server响应结果
     *
     * @param {*} response
     * @returns
     * @memberof Http
     */
    private _parseResult(response: any, dataType: string) {
        // console.log("--------------_parseResult-----------", response);
        if (dataType === DATA_TYPE.JSON || dataType === DATA_TYPE.JSONP) {
            return response.json();
        } else if (dataType === DATA_TYPE.TEXT) {
            return response.text();
        } else {
            let contentType = response.headers.get("Content-Type");
            if (/text/.test(contentType)) {
                return response.text();
            } else if (/form/.test(contentType)) {
                return response.formData();
            } else if (/video/.test(contentType)) {
                return response.blob();
            } else if (/json/.test(contentType)) {
                return response.json();
            }

            throw new Error(`不支持的content-type ${contentType}`);
        }
    }

    /**
     * obj2str
     *
     * @param {Object} data
     * @returns k1=v1&k2=v2
     * @memberof Http
     */
    private _obj2Str(data: any) {
        let ret = "";
        if (!data) return ret;

        for (let k in data) {
            ret += (ret === "" ? "" : "&") + k + "=" + data[k];
        }

        return ret;
    }

    /**
     * 组织fetch body
     *
     * @memberof Http
     */
    private _initFetch = (options: IHttpOptions) => {
        const { method, dataType } = options;

        if (dataType === DATA_TYPE.JSONP || method === METHOD.GET) {
            delete options.body;
        }

        delete options.dataType;

        return options as RequestInit;
    };

    private _initFetchJsonp = (options: IHttpOptions) => {
        let init: fetchJsonp.Options = {
            timeout: options.timeout,
        };
        return init;
    };
}

export default new Http();
